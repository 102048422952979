<template>
  <v-card tile
          flat
          dark
          color="blue-grey darken-3"
          class=""
  >
    <v-card-title class="pb-0">
      健康判断
    </v-card-title>
    <v-card-text class="d-flex justify-end">
      <div class="mr-2 font-weight-black">结论:</div>
      <div style="width: 33%" class="d-flex flex-column">
        <div v-for="d in diagnoses.sure"
             :key="d.id"
             class="font-weight-bold"
        >
          {{ d.name }}
        </div>
        <v-divider v-if="diagnoses.suspected.length" class="my-1"/>
        <div v-for="d in diagnoses.suspected"
             :key="d.id"
        >
          {{ d.name }}(疑似)
        </div>
      </div>
    </v-card-text>
    <v-divider class="my-4"/>
    <v-card-title>
      诊断编辑
      <v-spacer/>
      <v-btn icon
             @click="showEditor=!showEditor"
      >
        <v-icon>mdi-chevron-{{ showEditor ? 'up' : 'down' }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-expand-transition>
      <div v-show="showEditor" class="pa-4">
        <v-card-text class="border-1 rounded red--text mb-4 pt-0">
          <v-subheader class="pl-0 font-weight-black">确诊</v-subheader>
          <draggable id="sure-group"
                     v-model="diagnoses.sure"
                     :group="{name: 'group'}"
                     animation="300"
                     style="min-height: 32px"
                     class="d-flex flex-column"
          >
            <v-chip v-for="d in diagnoses.sure"
                    :key="d.id"
                    label
                    outlined
                    close
                    class="mb-2"
                    @click:close="toggleOption(d)"
            >
              <v-icon color="grey" small left>mdi-reorder-horizontal</v-icon>
              {{ d.name }}
            </v-chip>
          </draggable>
        </v-card-text>
        <v-card-text class="border-1 rounded lime--text pt-0">
          <v-subheader class="pl-0">疑似</v-subheader>
          <draggable id="suspected-group"
                     v-model="diagnoses.suspected"
                     :group="{name: 'group'}"
                     animation="300"
                     style="min-height: 32px"
                     class="d-flex flex-column"
          >
            <v-chip v-for="d in diagnoses.suspected"
                    :key="d.id"
                    label
                    outlined
                    close
                    class="mb-2"
                    @click:close="toggleOption(d)"
            >
              <v-icon color="grey" small left>mdi-reorder-horizontal</v-icon>
              {{ d.name }}
            </v-chip>
          </draggable>
        </v-card-text>
        <v-text-field v-model="custom"
                      label="自定义诊断"
                      hide-details
                      clearable
                      append-outer-icon="mdi-plus"
                      class="my-2"
                      @keypress.enter="addCustom"
                      @click:append-outer="addCustom"
        />

        <v-divider class="my-4"/>

        <v-card-text class="border-1 rounded blue-grey--text pt-0">
          <v-subheader class="pl-0">
            候选项{{ diagnoseOptions && diagnoseOptions.length ? '' : '(无候选项)' }}
          </v-subheader>
          <template v-for="(group ,i) in diagnoseOptions">
            <div class="d-flex align-center my-1">
              <span class="mr-4">诊断组合{{ i + 1 }}:</span>
              <v-chip v-for="d in group"
                      :key="d.id"
                      class="mr-2"
                      small
                      @click="toggleOption(d)"
              >
                <v-icon v-if="[...diagnoses.sure, ...diagnoses.suspected].find(e=>e.id===d.id)" small left>mdi-check
                </v-icon>
                {{ d.name }}
              </v-chip>
            </div>
            <v-divider/>
          </template>
          <v-autocomplete
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              no-filter
              :auto-select-first="false"
              :hide-selected="false"
              hide-no-data
              hide-selected
              item-text="name"
              item-value="id"
              label="诊断搜索"
              placeholder="输入关键字"
              prepend-icon="mdi-database-search"
              return-object
              style="z-index: 10"
              :class="{'mt-4': diagnoseOptions && diagnoseOptions.length}"
              @keypress.enter="goSearch(search)"
              @change="selectFromSearch($event)"
          ></v-autocomplete>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: "DiagnoseSelector",
  components: {draggable},
  props: {
    inputDiagnoses: Object,
    diagnoseOptions: Array
  },
  data: () => ({
    showEditor: false,
    diagnoses: {
      sure: [],
      suspected: []
    },
    custom: null,
    items: [],
    isLoading: false,
    search: null,
  }),
  watch: {
    inputDiagnoses: {
      immediate: true,
      // deep: true,
      handler(v) {
        if (!(v && v.suspected)) return
        this.diagnoses = JSON.parse(JSON.stringify(v))
      }
    },
    diagnoses: {
      deep: true,
      handler(v) {
        // console.log(v)
        this.$emit('update', v)
      }
    }
  },
  mounted() {
    this.showEditor = this.diagnoseOptions?.length
  },
  methods: {
    toggleOption(d) {
      let flag = false
      Object.keys(this.diagnoses).forEach(k => {
        const idx = this.diagnoses[k].findIndex(e => e.id === d.id)
        if (idx > -1) {
          this.diagnoses[k].splice(idx, 1)
          flag = true
        }
      })
      if (!flag) this.diagnoses.sure.push(d)
    },

    goSearch(val) {
      if (this.isLoading || !val) return
      this.isLoading = true
      this.$store.dispatch('request', {
        action: 'searchDiagnose',
        params: {
          keywords: val,
        },
        triggerLoading: false,
        fn: (data) => {
          this.items = data
        }
      })
          .finally(() => this.isLoading = false)
    },

    addCustom() {
      Object.keys(this.diagnoses).forEach(k => {
        const idx = this.diagnoses[k].findIndex(d => d.id === 'custom')
        if (idx > -1) {
          this.diagnoses[k].splice(idx, 1)
        }
      })
      this.diagnoses.sure.push({id: 'custom', name: this.custom})
    },

    selectFromSearch(e) {
      if (!e) return
      let flag = false
      Object.keys(this.diagnoses).forEach(k => {
        const idx = this.diagnoses[k].findIndex(d => d.id === e.id)
        if (idx > -1) {
          this.$pop(e.name + '已经存在!')
          flag = true
        }
      })
      if (!flag) this.diagnoses.sure.push(e)
    },
  }
}
</script>

<style scoped>

</style>